export const stepsConfig = [{
  text: `Always in the game, you will not miss any event (match),
    Champions League, English Premier League, La Liga, Seria\xa0A, Bundesliga, Ligue\xa01, etc.`,
  imgWebp: '/steps/events.webp',
  imgPng: '/steps/events.png',
  height: 516,
  width: 696,
  time: 'ALL top leagues ALL events',
}, {
  text: 'Select any events, select rules, invite whoever you want - it\'s easy.',
  imgWebp: '/steps/pick2.png',
  imgPng: '/steps/pick2.png',
  height: 516,
  width: 696,
  time: 'Create unique PRIVATE POOL',
}, {
  text: 'Select the number of random opponents - one? three? or fifty? join exciting battles and experience a storm of emotions.',
  imgWebp: '/steps/pick3.png',
  imgPng: '/steps/pick3.png',
  height: 516,
  width: 696,
  time: 'Enter SYSTEM POOLs with randon opponents',
}, {
  text: `Simple direct relationship probability / coefficient.
    Always in line with global trends, but without bookmaker's margin and cash flow. 100% probability for all outcomes of the match`,
  imgWebp: '/steps/pick4.png',
  imgPng: '/steps/pick4.png',
  height: 516,
  width: 696,
  time: 'POINTS are calculated from Probability %',
}, {
  text: `Bet on Score "in doubt"? it doesn't matter,
    there is still a bet on Result, stay in the game until the end. Hmm, bookmakers don't have such bets ...`,
  imgWebp: '/steps/pick5.png',
  imgPng: '/steps/pick5.png',
  height: 516,
  width: 696,
  time: 'PLACE BET on SCORE get auto BACKUP BET for RESULT',
}, {
  text: `The total probability for both Main bet and Backup bet.
    The higher the probability for Main bet, the fewer points for the Backup bet (and vice versa).
    Use different combinations and strategies.`,
  imgWebp: '/steps/pick6.png',
  imgPng: '/steps/pick6.png',
  height: 516,
  width: 696,
  time: 'UNIQUE and FAIR algorithm for calculating points',
}, {
  text: `A unique opportunity to make a prediction with one bet on two Scores at once,
    and for different Results (1X, X2, 12).  More combinations, more strategies for you, try it.`,
  imgWebp: '/steps/pick7.png',
  imgPng: '/steps/pick7.png',
  height: 516,
  width: 696,
  time: 'PLACE COMBO BET for Score or Result',
}, {
  text: `A lost bet does not mean a defeat, follow your opponents' bets.
    How do you like this idea?`,
  imgWebp: '/steps/pick8.png',
  imgPng: '/steps/pick8.png',
  height: 516,
  width: 696,
  time: 'KEEP in the game LONGER',
}, {
  text: `A simple rule, who correctly predicted,
    scored more points and win.`,
  imgWebp: '/steps/pick9.png',
  imgPng: '/steps/pick9.png',
  height: 516,
  width: 696,
  time: 'GET POINTS for correct predictions WIN POOL',
}, {
  text: `The same line of yours will play with other random opponents and their rates.
    Try your line in different ways.`,
  imgWebp: '/steps/pick10.png',
  imgPng: '/steps/pick10.png',
  height: 516,
  width: 696,
  time: 'COPY and MULTIPLY BETSLIP',
}, {
  text: `Unified instruments
    monitoring your efficiency, compare with others
  `,
  imgWebp: '/steps/pick11.png',
  imgPng: '/steps/pick11.png',
  height: 516,
  width: 696,
  time: 'Performance RATE VIRTUAL COINS for ROI',
}];
