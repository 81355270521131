import { useState } from 'react';
import PopUp from 'components/modal';

import styles from './carousel.module.css';

const CarouselImage = ({ image }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <picture className={styles.imageBox} onClick={() => setShowModal(true)}>
        <source srcSet={image.imgWebp} type="image/webp" />
        <img src={image.imgPng} alt={image.time} className={styles.image} height={image.height} width={image.width} loading="lazy" />
      </picture>
      <PopUp
        open={showModal}
        handleClose={() => setShowModal(false)}
        title={image.time}
      >
        <picture className={styles.modalImage}>
          <source srcSet={image.imgWebp} type="image/webp" />
          <img src={image.imgPng} alt={image.time} className={styles.image} height={image.height} width={image.width} loading="lazy" />
        </picture>
      </PopUp>
    </>
  )
};

export default CarouselImage;
