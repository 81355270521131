import {
  useContext, useState, useCallback, useEffect,
} from 'react';
import cx from 'classnames';
import Link from 'next/link';
import { useLazyQuery } from '@apollo/client';
import { FIND_TICKETS } from 'lib/api';
import { SportsMeta, FormatsMeta } from 'utils/game';
import SmallCarousel from 'components/carousel/small';
import ByTriggerSignIn from 'components/withSignIn/byTrigger';
import PoolBlock from 'components/bets/poolBlock';
import MainCarousel from 'components/mainCarousel';
import { MediaContext } from 'components/mediaQuery';
import MoneyIcon from 'public/landing/icons/money.svg';
import RegistrationIcon from 'public/landing/icons/registration.svg';
import RulesIcon from 'public/landing/icons/rules.svg';
import VerificationIcon from 'public/landing/icons/verification.svg';

import ArrowIcon from 'public/icons/arrow_right.svg';

import styles from './index.module.css';

const Home = () => {
  const [
    getPools,
    { data = {} },
  ] = useLazyQuery(FIND_TICKETS);
  const [triggerShow, setTriggerShow] = useState(false);
  const { mobile } = useContext(MediaContext);
  const closeCallBack = useCallback(() => setTriggerShow(false), []);
  const [pools, setPools] = useState([]);
  const [selChampionship, setSelChampionship] = useState('');

  const filterPools = (championship) => {
    if (championship === selChampionship) {
      setSelChampionship('');
      setPools(data.findTickets || []);
    } else {
      setSelChampionship(championship);
      setPools((data.findTickets || []).filter(({ events }) => {
        return events.some((event) => {
          return (event?.competition?.name || '').toLowerCase().includes(championship); 
        });
      }))
    }
  };

  useEffect(() => {
    if (data.findTickets) {
      setPools(data.findTickets || []);
    }
  }, [data]);

  useEffect(() => {
    getPools(SportsMeta[0].name);
    const root = document.documentElement;
    root.style.setProperty('--main-container-pad-t', '0');
    root.style.setProperty('--main-container-pad-x', '0');
    root.style.setProperty('--main-container-mob-pad-t', 0);
    root.style.setProperty('--body-background-position', 'center');

    return () => {
      root.style.removeProperty('--main-container-pad-t');
      root.style.removeProperty('--main-container-pad-x');
      root.style.removeProperty('--main-container-mob-pad-t');
      root.style.removeProperty('--body-background-position');
    };
  }, []);

  return (
    <ByTriggerSignIn show={triggerShow} closeCallBack={closeCallBack}>
      <div className="text-center">
        <h1 className={styles.mainTitle}>
          <span>Free</span>
          {' '}
          FOOTBALL POOLS
          {' '}
          <span>Hub</span>
        </h1>
        <p className={styles.mainP}>
          No Real Money Gambling
        </p>
        <p className={styles.mainP}>
          Tournaments with Correct Points for Predicted Match Results
        </p>
        <ul className={styles.benefit}>
          <li>
            <RegistrationIcon />
            <span><span>EASY</span> registration</span>
          </li>
          <li>
            <MoneyIcon />
            <span><span>NO</span> payment</span>
          </li>
          <li>
            <VerificationIcon />
            <span><span>NO</span> verification</span>
          </li>
          <li>
            <RulesIcon />
            <span>Simple&Clear rules</span>
          </li>
          <li>
            <img src="/landing/icons/roi.png" width={26} height={26} alt="roi" />
            <span><span>ROI /</span> Raiting</span>
          </li>
        </ul>
        <div className={styles.smallCarousel}>
          <h3 className={cx(styles.smallHeader, styles.f)}>
            <span className={cx(styles.item, styles.f)}>Football Pools</span>
          </h3>
          <SmallCarousel className={styles.championsList}>
            <div 
              onClick={() => filterPools('english premier league')}
              className={cx(styles.smallItem, styles.premier_league_england, {
                [styles.activeCam]: selChampionship === 'english premier league'
              })} 
            />
            <div 
              onClick={() => filterPools('spanish la liga')} 
              className={cx(styles.smallItem, styles.laliga_spain, {
                [styles.activeCam]: selChampionship === 'spanish la liga'
              })} 
            />
            <div 
              onClick={() => filterPools('italian seria')} 
              className={cx(styles.smallItem, styles.serie_a_italy, {
                [styles.activeCam]: selChampionship === 'italian seria'
              })} 
            />
            <div 
              onClick={() => filterPools('bundesliga')} 
              className={cx(styles.smallItem, styles.bundesliga_germany, {
                [styles.activeCam]: selChampionship === 'bundesliga'
              })}
            />
            <div 
              onClick={() => filterPools('french ligue')} 
              className={cx(styles.smallItem, styles.ligue1_france, {
                [styles.activeCam]: selChampionship === 'french ligue'
              })} 
            />
            <div 
              onClick={() => filterPools('champions league')} 
              className={cx(styles.smallItem, styles.champions_league, {
                [styles.activeCam]: selChampionship === 'champions league'
              })} 
            />
            <div 
              onClick={() => filterPools('euro')} 
              className={cx(styles.smallItem, styles.euro, {
                [styles.activeCam]: selChampionship === 'euro'
              })} 
            />
            <div 
              onClick={() => filterPools('uefa champions league')} 
              className={cx(styles.smallItem, styles.uefa_nations, {
                [styles.activeCam]: selChampionship === 'uefa champions league'
              })} 
            />
            <div 
              onClick={() => filterPools('europa league')} 
              className={cx(styles.smallItem, styles.europe_league, {
                [styles.activeCam]: selChampionship === 'europa league'
              })} 
            />
            <div 
              onClick={() => filterPools('conference league')}
              className={cx(styles.smallItem, styles.conference_league, {
                [styles.activeCam]: selChampionship === 'conference league'
              })} 
            />
            <div 
              onClick={() => filterPools('english championship')} 
              className={cx(styles.smallItem, styles.efl_championship, {
                [styles.activeCam]: selChampionship === 'english championship'
              })} 
            />
            <div 
              onClick={() => filterPools('mls')} 
              className={cx(styles.smallItem, styles.mls_usa, {
                [styles.activeCam]: selChampionship === 'mls'
              })} 
            />
            <div 
              onClick={() => filterPools('saudi pro league')}
              className={cx(styles.smallItem, styles.roshn_saudi, {
                [styles.activeCam]: selChampionship === 'saudi pro league'
              })} 
            />
          </SmallCarousel>
          { pools.length !== 0 && (
            <SmallCarousel className={styles.poolsBlock} stepInd={mobile ? 1 : 2}>
              {pools.map(({
                id,
                name,
                formats,
                rounds,
                events,
                eventIds,
              }) => (
                <Link
                  href={{
                    pathname: '/bets/create',
                    query: { pool: id },
                  }}
                  key={id}
                >
                  <a className={cx(styles.grayLink, 'block')}>
                    <PoolBlock
                      id={id}
                      small={true}
                      name={name}
                      prices={formats}
                      eventIds={eventIds}
                      events={events}
                      rounds={rounds}
                      className={styles.grayPool}
                      startAt={events[0]?.startTime}
                    />
                  </a>
                </Link>
              ))}
            </SmallCarousel>
          )}
          {pools.length === 0 && <p className={cx(styles.noItem, 'pt-2 font-bold text-lg')}>No active pools 😔</p>}
        </div>
        <h2 className={styles.subTitle}>How does Poolup work?</h2>
        <section className={styles.workBox}>
          <div className={styles.workElem}>
            <div className={styles.workIcon}>
              <img src="/landing/tournament.png" width={mobile ? 100 : 130} height={mobile ? 70 : 91} alt="tournament" />
            </div>
            <h3>Tournament</h3>
            <p>
              The tournament consists of multiple events, and players earn points for each correctly predicted event.
              The winner is the one who accumulates the most points at the end of all tournament events.
              The significance of a mistake in a single match is not high, as the victory goes to the one who
              consistently predicts better.
            </p>
            <div className={styles.workArrow}>
              <ArrowIcon width={mobile ? '40px' : '64px' } height={mobile ? '40px' : '64px' } />
              <ArrowIcon width={mobile ? '40px' : '64px' } height={mobile ? '40px' : '64px' } />
            </div>
            <div className={styles.stepBlock}>
              <div className={styles.stepItem}>
                <img src="/landing/system-pools.png" width={mobile ? 70 : 106} height={mobile ? 47 : 71} alt="system pools" />
                <h4>System pool</h4>
                <p>You can play with random players by the Poolup service. You determine the number of opponents in the pool, the more players, the more thrilling the game.</p>
              </div>
              <div className={styles.stepItem}>
                <div className={styles.stepIcon}>
                  <img src="/landing/private-pools.png" width={mobile ? 66 : 102} height={mobile ? 47 : 71} alt="private pools" />
                </div>
                <h4>Private pool</h4>
                <p>Create private pools, it's a breeze - just a few clicks away. Play for enjoyment and compete with your friends, without any bookmaker's margin, and the cash stays within your close circle.</p>
              </div>
            </div>
          </div>
          <div className={styles.workElem}>
            <div className={styles.workIcon}>
              <img src="/landing/backup-bet.png" width={mobile ? 168 : 218} height={ mobile ? 70 : 91 } alt="backup prediction" />
            </div>
            <h3>Backup Prediction</h3>
            <p>
              It's simple: you make a prediction on the match score and receive two predictions in one! The main prediction is for the score (maximum points). In case your main prediction doesn't hit the mark, you have a backup prediction for an identical outcome (fewer points). This boosts your positive expectations significantly!
            </p>
            <div className={styles.workArrow}>
              <ArrowIcon width={mobile ? '40px' : '64px' } height={mobile ? '40px' : '64px' } />
              <ArrowIcon width={mobile ? '40px' : '64px' } height={mobile ? '40px' : '64px' } />
            </div>
            <div className={cx(styles.stepBlock, styles.second)}>
              <div className={styles.stepItem}>
                <img src="/landing/combo-results-small.png" width={mobile ? 162 : 187} height={mobile ? 47 : 54} alt="combo scores small" />
                <h4>Combo scores</h4>
                <p>You can select two scores with a single prediction (combo prediction option). The number of points will be reduced, but the probability of guessing the score will significantly increase.</p>
              </div>
              <div className={styles.stepItem}>
                <img src="/landing/combo-scores-small.png" width={mobile ? 162 : 187} height={mobile ? 47 : 54} alt="combo results small" />
                <h4>Combo results</h4>
                <p>With a single prediction, you can choose two scores for two different outcomes. Even fewer points, but the risk of not scoring any points becomes minimal. Try different tactics and strategies.</p>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.points}>
          <div className={styles.pointArrow}>
            <ArrowIcon width="80px" height="80px" />
          </div>
          <h2>How are points counted?</h2>
          <img src="/landing/probability-points.png" width={mobile ? 180 : 241} height={mobile ? 67 : 90} alt="probability points" />
          <h5>Probability % / points</h5>
          <p>
            Use the built-in points calculator to see how the points change based on your choices.
            <span>The number of points for correctly guessed predictions is determined based on the probability of these outcomes occurring. We closely monitor betting market trends and ensure the adequacy of the offered odds for each option.</span>
          </p>
        </section>
        <section className={styles.formats}>
          <h2>Play format</h2>
          <div className={styles.formatBox}>
            {FormatsMeta.map((it) => (
              <div key={it.multiplier} className={cx(styles.formatItem, styles[it.multiplier])}>
                <h5>{it.multiplier}</h5>
                <p className="text-xs">{it.description}</p>
                <p className="text-xs">{it.winnerDescription}</p>
              </div>
            ))}
          </div>
        </section>
        <MainCarousel />
      </div>
    </ByTriggerSignIn>
  );
};

export default Home;
