import { useEffect, useState, useRef } from 'react';
import Carousel from 'components/carousel/index';
import { stepsConfig } from 'utils/homeConfig';

const MainCarousel = ({ className }) => {
  const carouselRef = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (carouselRef.current) {
      function handleIntersection(entries) {
        entries.map((entry) => {
          if (entry.isIntersecting) {
            setShow(true);
            observer?.disconnect?.();
          }
        });
      }
      const observer = new IntersectionObserver(handleIntersection);
      observer.observe(carouselRef.current);
    }
  }, [carouselRef.current]);

  return (
    <section ref={carouselRef}  className={className}>
      {show ? <Carousel config={stepsConfig} /> : null }
    </section>
  );
};

export default MainCarousel;
