import {
  useState, useRef, useEffect, useContext,
} from 'react';
import cx from 'classnames';
import Swiper, { Pagination, A11y } from 'swiper';

import { MediaContext } from 'components/mediaQuery';
import CarouselImage from './image';

import styles from './carousel.module.css';

Swiper.use([Pagination, A11y]);

const getProgress = (itemProgress, currentProgress, itemLength) => {
  let timelineProgress = 0;
  if (itemProgress - currentProgress >= 0 && itemProgress - currentProgress <= itemLength) {
    timelineProgress = Math.round((1 - (itemProgress - currentProgress) / itemLength) * 100);
  } else if (currentProgress >= itemProgress) { timelineProgress = 100; }
  return timelineProgress;
};

const getOpacity = (itemProgress, currentProgress, itemLength) => {
  let opacity = 0.2;
  const diff = itemProgress - currentProgress;

  if (Math.abs(diff) < itemLength) {
    opacity = 1 - 0.8 * (Math.abs(diff) / itemLength);
  } if (itemProgress === currentProgress) {
    opacity = 1;
  }

  return opacity;
};

const Carousel = ({ className, config }) => {
  const [currentProgress, setCurrentProgress] = useState(0);
  const [timelineData, setTimeLineData] = useState([]);
  const [slideWidth, setSlideWidth] = useState(0);
  const [timelineOffset, setTimelineOffset] = useState(0);
  const [timelineTranslate, setTimelineTranslate] = useState(0);
  const [inTransition, setInTransition] = useState(false);
  const el = useRef();
  const pagination = useRef();
  const timelineItems = useRef([]);
  const media = useContext(MediaContext);
  const tabletBreakpoint = media.tablet;
  const swiperInstance = useRef(null);

  useEffect(() => {
    const initSwiper = async () => {
      swiperInstance.current = new Swiper(el.current, {
        direction: 'horizontal',
        loop: true,
        centeredSlides: true,
        pagination: { el: pagination.current, dynamicBullets: true },
        watchSlidesVisibility: true, // adds swiper-slide-visible class to visible slides
        on: {
          progress: (_, progress) => {
            let roundedProgress = parseFloat(progress.toFixed(3));
            if (roundedProgress < 0) {
              roundedProgress = 0;
            } else if (roundedProgress > 1) {
              roundedProgress = 1;
            }
            setCurrentProgress(roundedProgress);
          },
          init(swiper) {
            setSlideWidth(swiper.slidesSizesGrid[0]);
            setTimelineOffset(-swiper.slidesGrid[0]);
          },
          resize(swiper) {
            setSlideWidth(swiper.slidesSizesGrid[0]);
            setTimelineOffset(-swiper.slidesGrid[0]);
          },
          transitionStart: () => {
            setInTransition(true);
          },
          transitionEnd: () => { setInTransition(false); },
        },
      });
    };
    const swiper = swiperInstance.current;
    if (!swiper || swiper.destroyed) {
      initSwiper();
    }

    return () => { if (swiper && !swiper.destroyed) { swiper.destroy(); } };
  }, []);

  useEffect(() => {
    const swiper = swiperInstance.current;
    if (swiper && !swiper.destroyed) {
      swiper.params.slidesPerView = tabletBreakpoint ? 1.25 : 2.05;
      swiper.params.spaceBetween = tabletBreakpoint ? 24 : 16;
      swiper.update();
    }
  }, [tabletBreakpoint]);

  useEffect(() => {
    const itemLength = parseFloat((1 / (config.length - 1)).toFixed(3));
    const newTimelineData = config.map((item, index) => {
      const { time } = item;
      const itemProgress = index * itemLength;
      const active = itemProgress === currentProgress;
      const timelineProgress = getProgress(itemProgress, currentProgress, itemLength);
      const opacity = getOpacity(itemProgress, currentProgress, itemLength);
      return {
        time, active, timelineProgress, itemProgress, opacity,
      };
    });
    setTimeLineData(newTimelineData);
  }, [config, currentProgress]);

  useEffect(() => {
    const itemLength = parseFloat((1 / (config.length - 1)).toFixed(3));
    const widths = timelineItems.current.map((element) => element.offsetWidth);
    const currentItem = Math.floor(currentProgress / itemLength);
    const offset = widths.reduce((acc, item, index) => {
      // eslint-disable-next-line no-param-reassign
      if (index < currentItem) { acc += item; }
      // eslint-disable-next-line no-param-reassign
      if (index === currentItem) { acc += item * (currentProgress / itemLength - currentItem); }
      return acc;
    }, 0);
    setTimelineTranslate(-offset + slideWidth / 2 - widths[currentItem] / 2);
  }, [config.length, currentProgress, timelineItems, slideWidth, tabletBreakpoint]);

  return (
    <div className={cx(styles.component, className)}>
      {' '}
      <div className={styles.timeline}>
        {' '}
        <div
          className={styles.timelineWrapper}
          style={{
            transform: `translate3d(${timelineOffset + timelineTranslate}px, 0, 0)`, transition: `all ${inTransition ? 300 : 0}ms ease 0s`,
          }}
        >
          {' '}
          {timelineData.map(({ time, timelineProgress, opacity }, index) => (
            <div key={time} className={styles.timelineItem} ref={(item) => { timelineItems.current[index] = item; }}>
              {' '}
              {index !== 0 && (
              <div className={cx(styles.line, { [styles.complete]: timelineProgress === 100 })}>
                {' '}
                <div
                  className={styles.lineInner}
                  style={{ width: `${timelineProgress}%`, transition: `all ${inTransition ? 300 : 0}ms ease 0s` }}
                />
                {' '}
              </div>
              )}
              {' '}
              <span className={cx(styles.time, { [styles.active]: timelineProgress > 0 })} style={{ opacity }}>
                {' '}
                {time}
                {' '}
              </span>
              {' '}
            </div>
          ))}
          {' '}
        </div>
        {' '}
      </div>
      {' '}
      <div ref={el} className={cx('swiper-container', styles.swiperContainer)}>
        {' '}
        <div className="swiper-wrapper">
          {' '}
          {config.map((item) => (
            <div className="swiper-slide" key={item.time}>
              <CarouselImage image={item} />
              {' '}
              <p>{item.text}</p>
              {' '}
            </div>
          ))}
          {' '}
        </div>
        <div ref={pagination} className="swiper-pagination" />
        {!tabletBreakpoint && (
        <>
          <div
            onClick={() => swiperInstance.current?.slidePrev()}
            className={cx('swiper-button-prev', { 'button-ds': swiperInstance.current?.activeIndex === 0 })}
          />
          <div
            onClick={() => swiperInstance.current?.slideNext()}
            className={cx('swiper-button-next', { 'button-ds': swiperInstance.current?.activeIndex === config.length - 1 })}
          />
        </>
        )}
      </div>
      {' '}
    </div>
  );
};

export default Carousel;
